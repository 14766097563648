export const CREDIT_CARD = 'creditcard';
export const APPLE_PAY = 'applepay';
export const API_KEY = process.env.REACT_APP_API_KEY;
export const ONRAMPER_TRY_API_KEY = 'pk_prod_01GQS0CRGNRXXGV3A0S3A0AEWY';
export const DEFAULT_TOKEN_URL = 'https://cdn.onramper.com/default-token.svg';
export const EMPTY_ICON_URL = 'https://s3.amazonaws.com/cdn.onramper.com/empty.svg';

// NOTE : Do not override !
export const ONRAMPER_WIDGET_API_KEY = process.env.REACT_APP_API_KEY;
export const COINIFY_OTC_URL =
  'https://tktu030sedr.typeform.com/to/UOT391Dz#email=&trader_id=&partner_id=1165e86f-c77c-4996-9c18-9d54a5c8103a&partner_name=Onramper-2';

export const GB_COUNTRY_CODE = 'GB';

