import clsx from "clsx";
import React, { FC, useCallback, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useSignIn } from "../../../hooks/useSignIn";
import { useVerifyOtp } from "../../../hooks/useVerifyOtp";
import { useIndexedDbContext, useNavigationContext } from "../../../providers";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import AmountSummary from "../../common/AmountSummary/AmountSummary";
import ViewHeader from "../../common/ViewHeader/ViewHeader";
import Steps from "../Steps";
import styles from "./VerifyEmail.module.css";
import { useLanguage } from "../../../hooks/useLanguage";
import ContainerView from "../ContainerView";
import { useRumEvents } from "../../../hooks/useRumEvents";

type VerifyEmailProps = {
  signInFrom: "menu" | "checkout" | "session-timedout";
};

export const VerifyEmail: FC<VerifyEmailProps> = (props: VerifyEmailProps) => {
  const { signInFrom } = props;
  const { formatMessage } = useLanguage();
  const { backScreen, onlyScreen, nextScreen } = useNavigationContext();
  const { setAuthToken } = useIndexedDbContext();
  const { auth, setOtp, setIsAuthenticated } = useAuthContext();
  const { otp, email } = auth;
  const {
    isLoading: isVerifyingOtp,
    error,
    data,
    mutate: verifyOtp,
  } = useVerifyOtp();
  const { mutate: signIn } = useSignIn();
  const [otpError, setOtpError] = useState<string | null>(null);
  const { status, jwt } = data ?? {};
  const [timer, setTimer] = useState<number | null>(null);
  const [isProcessingOtpRes, setIsProcessingOtpRes] = useState<boolean>(false);

  useEffect(() => {
    if (otp?.length === 6) verifyOtp();
  }, [otp]);

  useEffect(() => {
    const handleVerifyOtpResponse = async () => {
      if (status === "success" && jwt) {
        setIsProcessingOtpRes(true);
        setIsAuthenticated(true);
        await setAuthToken(jwt);
        processScreens();
      } else if (status === "failed" || error) {
        setOtpError(formatMessage("verifyEmailView.body.otpError"));
      }
    };
    handleVerifyOtpResponse();
  }, [status]);

  useEffect(() => {
    return () => {
      setOtp("");
    };
  }, [setOtp]);

  useEffect(() => {
    if (timer !== null) {
      const countdown = setInterval(() => {
        setTimer((prev) => (prev ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer]);

  const processScreens = () => {
    if (signInFrom === "checkout") {
      nextScreen(<Steps key="Steps" />);
      setIsProcessingOtpRes(false);
    } else {
      onlyScreen(<ContainerView />);
    }
  };

  const resendOtpHandler = useCallback(() => {
    signIn();
    setOtpError(null);
    setOtp("");
    setTimer(20);
  }, [signIn, setOtp, setOtpError]);

  function renderOtpInput() {
    return (
      <OTPInput
        value={otp || ""}
        onChange={(otp) => {
          setOtp(otp);
          setOtpError(null);
        }}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        containerStyle={styles.otp}
        inputStyle={clsx(styles["otp-input"], {
          [styles["error"]]: otpError,
          [styles["disabled"]]: isVerifyingOtp || isProcessingOtpRes,
        })}
        shouldAutoFocus={true}
        inputType="number"
      />
    );
  }

  return (
    <div className={styles["view-root"]}>
      <ViewHeader
        backButtonAction={() => backScreen()}
        content={signInFrom === "checkout" ? <AmountSummary /> : null}
      />
      <div className={styles["view-body"]}>
        <span className={styles["view-body-title"]}>
          {formatMessage("verifyEmailView.body.title")}
        </span>
        <span className={styles["view-body-sub"]}>
          {formatMessage("verifyEmailView.body.sub")} <b>{email}</b>.
        </span>
        {renderOtpInput()}
        {otpError && (
          <span className={styles["otp-text-field-helper"]}>
            <span className={styles["otp-text-field-helper-text"]}>
              {otpError}
            </span>
          </span>
        )}
        {isProcessingOtpRes || isVerifyingOtp ? (
          <span className={styles["resend-code"]}>
            {formatMessage("signInView.body.helperText5")}
          </span>
        ) : (
          <span className={styles["resend-code"]}>
            {formatMessage("verifyEmailView.body.helperText1")}{" "}
            {timer ? (
              <span>
                {formatMessage("verifyEmailView.body.helperText2")} {timer}s
              </span>
            ) : (
              <span
                className={styles["resend-code-span"]}
                onClick={resendOtpHandler}
              >
                {formatMessage("verifyEmailView.body.helperText3")}
              </span>
            )}
          </span>
        )}
      </div>
    </div>
  );
};
