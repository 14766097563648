import { Transaction } from '../../types';
import { initialState } from './initialState';
import { TransactionActions, TransactionActionType } from './types';

export const transactionReducer = (state: Transaction, action: TransactionActions) => {
  switch (action.type) {
    case TransactionActionType.SetTransactionType:
      return { ...state, transactionType: action.payload };
    case TransactionActionType.SetFiatAmount:
      return { ...state, fiatAmount: action.payload };
    case TransactionActionType.SetCryptoAmount:
      return { ...state, cryptoAmount: action.payload };
    case TransactionActionType.SetSelectedFiat:
      return { ...state, selectedFiat: action.payload };
    case TransactionActionType.SetSelectedCrypto:
      return { ...state, selectedCrypto: action.payload };
    case TransactionActionType.SetSelectedOnramp:
      return { ...state, selectedOnramp: action.payload };
    case TransactionActionType.SetSelectedPaymentMethod:
      return { ...state, selectedPaymentMethod: action.payload };
    case TransactionActionType.SetQuotes:
      return { ...state, quotes: action.payload };
    case TransactionActionType.SetSelectedCountry:
      return { ...state, selectedCountry: action.payload };
    case TransactionActionType.SetRecurringPayment:
      return { ...state, isRecurringPayment: action.payload };
    case TransactionActionType.SetRecurringPaymentMetadata:
      return { ...state, recurringPaymentMetadata: action.payload };
    case TransactionActionType.ResetTransaction:
      return initialState;
    case TransactionActionType.SetTransactionError:
      return { ...state, error: action.payload };
    case TransactionActionType.SetOtcTxn:
      return { ...state, isOtcTxn: action.payload };
    default:
      throw new Error('Unhandled action type');
  }
};
