import {
  API_KEY,
  ONRAMPER_TRY_API_KEY,
} from "@onramper/widget-v2/src/constants";
import { ThemeName } from "@onramper/widget-v2/src/providers/ThemeContextProvider/types";
import { TabName, OnramperWidgetProps } from "../../../widget/src/types";

const allowedModes: TabName[] = ["buy", "sell", "swap"];

export const getUrlParams = (search: string): OnramperWidgetProps => {
  const urlParams = new URLSearchParams(search);

  const successRedirectUrl = urlParams.get("successRedirectUrl");
  const failureRedirectUrl = urlParams.get("failureRedirectUrl");
  const offrampCashoutRedirectUrl = urlParams.get("offrampCashoutRedirectUrl");

  //TODO: add generic methods to extract url
  function extractParam(name: string, defaultValue?: string) {
    return urlParams.get(name) ?? defaultValue;
  }

  const apiKey =
    urlParams.get("apikey") ||
    urlParams.get("apiKey") ||
    urlParams.get("ApiKey") ||
    API_KEY;
  const { supportSell, supportBuy, supportSwap, requestedModes } = getMode(urlParams, apiKey);

  const isRecurringPaymentsEnabled = (apiKey: string | undefined) => {
    const supportRecurringPaymentsParam = urlParams.get(
      "supportRecurringPayments"
    );

    if (supportRecurringPaymentsParam === null) {
      // disabled, unless the default key is used
      return apiKey === API_KEY;
    } else {
      return supportRecurringPaymentsParam === "true" ? true : false;
    }
  };

  const isOnramperKey =
    apiKey === API_KEY || //widget key
    apiKey === ONRAMPER_TRY_API_KEY; //try key

  const isOtcTxnSupported = () => {
    if (isOnramperKey) {
      return true;
    } else {
      return urlParams.get("supportOtcTxn") === "true" ? true : false;
    }
  };

  return {
    apiKey: apiKey,
    country: urlParams.get("country"),
    supportSell: supportSell,
    supportBuy: supportBuy,
    supportSwap: supportSwap,
    requestedModes,
    themeName: extractParam("themeName") as ThemeName,
    containerColor: extractParam("containerColor"),
    cardColor: extractParam("cardColor"),
    primaryColor: extractParam("primaryColor"),
    secondaryColor: extractParam("secondaryColor"),
    primaryTextColor: extractParam("primaryTextColor"),
    secondaryTextColor: extractParam("secondaryTextColor"),
    primaryBtnTextColor: extractParam("primaryBtnTextColor"),
    borderRadius: extractParam("borderRadius"),
    wgBorderRadius: extractParam("wgBorderRadius"),
    partnerContext: urlParams.get("partnerContext"),
    enableCountrySelector:
      urlParams.get("enableCountrySelector") === "true" ? true : false,
    supportLocalization: true,
    skipTransactionScreen:
      urlParams.get("skipTransactionScreen") === "true" ? true : false,
    txnRedirect: urlParams.get("txnRedirect") === "true" ? true : false,
    preventTxnClose: urlParams.get("preventTxnClose") === "true" ? true : false,
    successRedirectUrl:
      successRedirectUrl != null
        ? decodeURIComponent(successRedirectUrl)
        : null,
    failureRedirectUrl:
      failureRedirectUrl != null
        ? decodeURIComponent(failureRedirectUrl)
        : null,
    offrampCashoutRedirectUrl:
      offrampCashoutRedirectUrl != null &&
        offrampCashoutRedirectUrl.trim().length > 0
        ? decodeURIComponent(offrampCashoutRedirectUrl)
        : null,
    supportOtcTxn: isOtcTxnSupported(),
    enableAuth: false,
    txnType: urlParams.get("txnType") === "sell" ? "sell" : "buy",
    txnFiat: urlParams.get("txnFiat"),
    txnCrypto: urlParams.get("txnCrypto"),
    txnAmount: parseFloat(urlParams.get("txnAmount") || "0"),
    txnInput:
      urlParams.get("txnInput") === "destination" ? "destination" : "source",
    txnPaymentMethod: urlParams.get("txnPaymentMethod"),
    txnOnramp: urlParams.get("txnOnramp"),
    txnOfframp: urlParams.get("txnOfframp"),
    uuid: urlParams.get("uuid"),
    buy: {
      defaultCrypto: urlParams.get("defaultCrypto"),
      defaultFiat: urlParams.get("defaultFiat"),
      defaultAmount: parseFloat(urlParams.get("defaultAmount") || "0"),
      wallets: urlParams.get("wallets"),
      networkWallets: urlParams.get("networkWallets"),
      walletAddressTags: urlParams.get("walletAddressTags"),
      onlyCryptos: urlParams.get("onlyCryptos"),
      onlyCryptoNetworks: urlParams.get("onlyCryptoNetworks"),
      excludeCryptos: urlParams.get("excludeCryptos"),
      excludeCryptoNetworks: urlParams.get("excludeCryptoNetworks"),
      excludeFiats:
        urlParams.get("excludeFiats") || urlParams.get("excludeFiat"),
      onlyFiats: urlParams.get("onlyFiats") || urlParams.get("onlyFiat"),
      excludePaymentMethods: urlParams.get("excludePaymentMethods"),
      defaultPaymentMethod: urlParams.get("defaultPaymentMethod"),
      onlyOnramps: urlParams.get("onlyOnramps"),
      isAddressEditable:
        urlParams.get("isAddressEditable") === "false" ? false : true,
      isAmountEditable:
        urlParams.get("isAmountEditable") === "false" ? false : true,
      recommendation: urlParams.get("recommendation"),
      supportRecurringPayments: isRecurringPaymentsEnabled(apiKey),
      signature: urlParams.get("signature"),
    },
    sell: {
      defaultCrypto: extractParam("sell_defaultCrypto"),
      defaultFiat: extractParam("sell_defaultFiat"),
      defaultAmount: parseFloat(extractParam("sell_defaultAmount") || "0"),
      onlyCryptos: extractParam("sell_onlyCryptos"),
      onlyCryptoNetworks: extractParam("sell_onlyCryptoNetworks"),
      excludeCryptos: extractParam("sell_excludeCryptos"),
      excludeCryptoNetworks: extractParam("sell_excludeCryptoNetworks"),
      excludeFiats:
        extractParam("sell_excludeFiats") || extractParam("sell_excludeFiat"),
      onlyFiats:
        extractParam("sell_onlyFiats") || extractParam("sell_onlyFiat"),
      excludePaymentMethods: extractParam("sell_excludePaymentMethods"),
      defaultPaymentMethod: extractParam("sell_defaultPaymentMethod"),
      onlyOfframps: extractParam("onlyOfframps"),
      isAmountEditable:
        urlParams.get("sell_isAmountEditable") === "false" ? false : true,
      // partnerContext: extractParam("sell_partnerContext"),
      recommendation: extractParam("sell_recommendation"),
    },
    swap: {
      defaultSource: extractParam("swap_defaultSource"),
      defaultTarget: extractParam("swap_defaultTarget"),
      defaultAmount: extractParam("swap_defaultSource")
        ? parseFloat(extractParam("swap_defaultAmount") || "0")
        : undefined,
    },
    originatingHost: getOriginatingHost(),
  };
};

// Create a type guard to check if a string is a valid Mode
function isValidModeName(value: string): value is TabName {
  return allowedModes.includes(value as TabName);
}

function getMode(urlParams: URLSearchParams, apiKey: string | undefined) {
  const supportParam = urlParams.get("mode");

  let supportArray: string[] = supportParam ? supportParam.toLowerCase().split(",") : [];

  // Now that this prop contorls the ui, we need to ensure sanity
  supportArray = Array.from(new Set(supportArray)) // remove duplicates
  supportArray = supportArray.filter(isValidModeName);

  // if support param is not supplied, we enable sell by default ONLY for the default API_KEY
  const isSellEnabledByDefault = !supportParam && apiKey === API_KEY;

  const supportBuy = supportArray.includes("buy") || (!supportParam && true);
  const supportSell =
    supportArray.includes("sell") ||
    urlParams.get("supportSell") === "true" ||
    isSellEnabledByDefault;
  const supportSwap = supportArray.includes("swap");
  return { supportSell, supportBuy, supportSwap, requestedModes: supportArray };
}

export const getOriginatingHost = (): string | null => {
  try {
    // see https://stackoverflow.com/questions/3420004/access-parent-url-from-iframe
    const originUrl =
      window.location !== window.parent.location
        ? document.location.ancestorOrigins[0] || document.referrer
        : document.location.href;

    let host = new URL(originUrl as string).host;
    if (host.startsWith("www.")) {
      host = host.slice("www.".length);
    }
    return host;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("Failed to get originating host", err);
    return null;
  }
};
